import { NextRouter } from 'next/router';
import { Dispatch } from 'redux';
import { DEFAULT_ERROR_MESSAGE } from '~/constants/config/common';
import { PAGE_ROUTES } from '~/constants/pages';
import fetch from '~/helpers/fetch';
import { setNewRelicCustomAttribute, setNewRelicNoticeError } from '~/helpers/newrelic';
import slugify from '~/helpers/slugify';
import { trackEvent } from '~/helpers/tracking';
import {
  ActionTypes,
  Option,
  Product,
  SelectedProductsState,
  SelectProductAction,
  SetOfferTypeAction,
  SetProductsAction,
  SetSelectedSweetenerAction,
  SetPhoneSelection,
  SweetenerKeys,
  SetBroadbandTypeAction,
  GetProductsResponse,
  SelectedResumeProductsState,
  ClearSelectProductAction,
  FirstAvailableAction,
  SetLandingPageProduct,
  SetLandingPageProductAvailable,
  SetNDIBannerAction,
  SetSelectedNetflixAction,
  SetOfferObjectsAction,
  OfferBannerObject,
  SetOfferModalSelectedAction,
  SetIncludedWhiWholeHomeWifiPlus,
  SetCardConsolidation,
} from '~/types/products';
import { ReduxState } from '~/types/redux';
import { setEligibility, addAddressHistory, updateAddress, resetAddressRoute, setUnservReason } from './address';
import { setLegal } from './legal';
import { setC2F, setLiwPageReason, setPageLoading, setTracking } from './common';
import { setContact } from './contact-credit';
import { setNotifications } from './global';
import isNurtureCampaign, { nurtureRedirect } from '~/helpers/checkNurtureCampaign';
import Cookies from 'js-cookie';
import { PAPER_BILL_FEE } from '~/constants/entityIds';
import { setPaperBillFee } from './billing';
import clientSide from '~/helpers/clientSide';
import getOTKOfferType from '~/helpers/getOTKOfferType';
import { getTopOffer } from '~/helpers/getTopOffer';
import { LiwPageReasons } from '~/types/common';
import { formatMonarchEvaluations, formatPreampExperience, MonarchEvaluationsRuleSet, MonarchPreampExperience } from '@redventures/cohesion-utils-react';
import { OfferObject } from '~/types/offerObject';
import { SetCardConsolidationModal } from '../../types/products';
import { MonarchComponentReturn } from '~/types/monarch';

const { EERO, VRC } = SweetenerKeys;

interface PostBody {
  hasExistingService: boolean;
  isCopper2Fiber: boolean;
  isResume?: boolean;
  env?: string;
  controlNumber?: string;
  quoteNumber?: string;
  cartPage?: string;
  isResumeCredit?: boolean;
  mobileVerifyRequired?: string;
  token?: string;
  isAi?: boolean;
}

export const getProducts = (
  router: NextRouter,
  isCopper2Fiber = false,
  apiVersion: string,
  cartPage: string,
  monarch: ReduxState['monarch'],
  recaptchaToken: string | null,
  isAi?: boolean
) => {
  return async (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
    const { address, tracking, eligibility, addressHistory, resume, quiz } = getState();
    const addressHasExistingService = eligibility?.addressHasExistingService || false;
    const installAddress = address?.install || {};
    const { pathname } = router;
    const { voiceEligible } = address ?? {};
    const isResume = resume?.enabled === true;
    const resumeCredit = cartPage === '/contact-credit/' && isResume;
    const postBody: PostBody = {
      // force c2f to always be true since they have a copper line already.
      hasExistingService: isCopper2Fiber ? true : addressHasExistingService,
      isCopper2Fiber,
      isResume,
      isResumeCredit: resumeCredit,
      token: recaptchaToken || undefined,
      isAi,
    };

    // check if mobile verification is enabled in experience to pass to OL to enable MCV endpoints
    if (clientSide) {
      window?.cohesion('monarch:done', function (err, evaluations) {
        const rules: MonarchEvaluationsRuleSet = formatMonarchEvaluations(evaluations);
        if (rules?.trafficFlow?.value) {
          const experience = formatPreampExperience(rules.trafficFlow.value as unknown as MonarchPreampExperience | null);
          if (experience) {
            const placement = experience.placements.find(
              (placement) =>
                placement.placementName === 'address-check-page-config'
            ) as { metadata?: { verifyPhoneEnabled?: string } };
            const mobileVerificationEnabled =
              placement?.metadata?.verifyPhoneEnabled === 'true';
            postBody.mobileVerifyRequired = String(
              mobileVerificationEnabled
            ).toUpperCase();
          }
        }
      });
    }

    if (!isCopper2Fiber) {
      postBody.env = installAddress?.env || '';
      postBody.controlNumber = installAddress?.controlNumber || '';
    }

    const {
      success,
      quoteId,
      quoteNumber,
      accountUuid,
      availableProducts,
      broadbandType,
      legal,
      userErrorMessage,
      customer,
      gmtOffset,
      serviceAddress,
      unservReason,
      firstAvailable,
      productList,
      quoteCreated,
      defaultOffers,
    } = await fetch<GetProductsResponse>({
      endpoint: `${apiVersion}/products/get`,
      options: {
        method: 'POST',
        body: JSON.stringify(postBody),
      },
      tracking,
      dispatch,
    });

    if (unservReason) dispatch(setUnservReason(unservReason));
    if (firstAvailable) dispatch(setFirstAvailable(firstAvailable));

    try {
      if (success) {
        const filteredProducts = (availableProducts || []).filter((product) => product?.price?.amount >= 0);
        const hasProducts = Boolean(filteredProducts.length);
        if (quoteId) {
          dispatch(setTracking('quoteId', quoteId));
          Cookies.set('quoteId', quoteId, { expires: 30 });
          Cookies.set('quoteCreated', quoteCreated, { expires: 30 });
        }
        if (quoteNumber) dispatch(setTracking('quoteNumber', quoteNumber));
        if (accountUuid) dispatch(setTracking('accountUuid', accountUuid));
        if (broadbandType) dispatch(setBroadbandType(broadbandType));
        if (productList) dispatch(setOfferObjects(productList));
        // Track the users eligibility
        dispatch(
          setEligibility({
            addressHasExistingService,
            serviceable: hasProducts,
          })
        );

        if (serviceAddress) {
          const { addressKey } = serviceAddress;
          dispatch(updateAddress('install', serviceAddress));

          // If a single session repeatedly checks for different addresses,
          // send info to New Relic to flag as a potential bot
          if (addressKey && !addressHistory?.includes(addressKey)) {
            dispatch(addAddressHistory(addressKey));
            if (addressHistory.length >= 2) {
              setNewRelicCustomAttribute([{ name: 'MultipleAddressesCollected', value: 'true' }]);
            }
          }
        }

        //Determines if a user is eligible for specific offers such OFS or hyperlocal offers

        const is1GigPlan = availableProducts?.some(
          (product) => product?.productId === '2b09989e-1696-4cc2-b13c-7e0950b74588'
        );

        if (availableProducts) {
          const monarchOfferRankings = (monarch?.cartOfferRankings as MonarchComponentReturn)?.data
            ?.offerRankingList as OfferBannerObject[];

          const offers = monarchOfferRankings && monarchOfferRankings.length > 0 ? monarchOfferRankings : defaultOffers;
          Cookies.set('addressOfferType', getOTKOfferType(availableProducts));
          Cookies.set('1gig', is1GigPlan);
          dispatch(setProducts(availableProducts, dispatch, offers));
        }

        dispatch(
          setContact({
            firstname: customer?.firstName || '',
            lastname: customer?.lastName || '',
            email: customer?.email || '',
            phone: customer?.phone || '',
            gmtOffset,
          })
        );

        dispatch(setLegal(legal || {}));
        if (addressHasExistingService) {
          trackEvent({ action: 'movingToServiced' });
        }
        // FRAPI-444 flags an HOA address, which we have a special LIW page for
        else if (unservReason && unservReason.includes('FRAPI-444')) {
          dispatch(setLiwPageReason(LiwPageReasons.HOA_RESTRICTED));
          router.push(PAGE_ROUTES.LIW);
        } else if (!hasProducts && voiceEligible) router.push(PAGE_ROUTES.UNSERVICEABLE_ALLCONNECT);
        else if (hasProducts && !addressHasExistingService) {
          if (!resumeCredit) {
            if (isCopper2Fiber) dispatch(setC2F('enabled', true));
            if (pathname === '/my-custom-quote' || pathname === '/customize') {
              dispatch(setPageLoading(false));
              return;
            }
            const gdtFlow = quiz?.results && Object.keys(quiz.results).length > 0;
            const nextPage = isCopper2Fiber
              ? PAGE_ROUTES.PLAN_FIBER
              : gdtFlow
              ? PAGE_ROUTES.MY_CUSTOM_QUOTE_PLAN_PACKAGE
              : PAGE_ROUTES.PLAN_PACKAGE;
            router.push(nextPage);
          } else return;
        } else if (isCopper2Fiber && !hasProducts) {
          router.replace(PAGE_ROUTES.ADDRESS_CHECK);
        } else if (!addressHasExistingService || !hasProducts) {
          if (quoteId && !hasProducts) setNewRelicNoticeError(new Error('Has quoteId without products'));
          isNurtureCampaign(router.query) ? nurtureRedirect() : router.push(PAGE_ROUTES.UNSERVICEABLE_ALLCONNECT);
        } else {
          setNewRelicCustomAttribute([{ name: 'getProductsRouteMatch', value: 'false' }]);
          throw new Error(`getProducts next route not matched`);
        }
      } else {
        setNewRelicCustomAttribute([{ name: 'getProductsError', value: 'true' }]);
        throw new Error(`getProducts not successful`);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setNotifications([
          {
            type: 'error',
            message: userErrorMessage || DEFAULT_ERROR_MESSAGE,
          },
        ])
      );

      const prevPage =
        quiz?.quizSession && !quiz?.quizSessionFromLP ? PAGE_ROUTES.MY_CUSTOM_QUOTE : PAGE_ROUTES.ADDRESS_CHECK;
      router.push(prevPage, undefined, { shallow: true });
      dispatch(setPageLoading(false));

      dispatch(resetAddressRoute());
    }
  };
};

export const setProducts = (
  products: Product[],
  dispatch: Dispatch,
  offerRankingList?: OfferBannerObject[]
): SetProductsAction => {
  const available = {};
  const totalProducts = products?.length;
  const ngcOfferBannerOFSOverride = Cookies.get('addressOfferTypeOverride'); // TODO: Remove after NGC Offer Banners pass QA
  const addressOfferType = ngcOfferBannerOFSOverride
    ? ngcOfferBannerOFSOverride
    : Cookies.get('addressOfferType') ?? 'default';

  const offerLevelSweeteners = {
    has2Gig: false,
    sweetenerLegalKeys: [],
  };

  for (let i = 0; i < totalProducts; i++) {
    const product = products[i];
    const type = slugify(product.type);
    product.is200 = product?.dataspeed?.downloadInMbps === 200;
    product.is500 = product?.dataspeed?.downloadInMbps === 500;
    product.is1Gig = product?.dataspeed?.downloadInMbps === 1000;
    product.is2Gig = product?.dataspeed?.downloadInMbps === 2000;
    product.is5Gig = product?.dataspeed?.downloadInMbps === 5000;
    product.is7Gig = product?.dataspeed?.downloadInMbps === 7000;

    if (product?.dataspeed?.downloadInMbps === 2000) {
      offerLevelSweeteners.has2Gig = true;
    }

    if (product?.sweeteners?.eero && !offerLevelSweeteners.sweetenerLegalKeys.includes(EERO))
      offerLevelSweeteners.sweetenerLegalKeys.push(EERO);

    // Allows for dynamic paper bill fee
    if (product?.productId === PAPER_BILL_FEE) dispatch(setPaperBillFee(product?.price));

    // Get the top ranked offer
    if (offerRankingList) {
      const topOffer = getTopOffer(offerRankingList, product, addressOfferType);

      if (topOffer) {
        product.topOffer = topOffer;
      }
    }

    if (!type) continue;

    if (available[type]) {
      available[type] = [...available[type], product].sort((a, b) => a.price.amount - b.price.amount);
    } else {
      available[type] = [product];
    }
  }

  return { type: ActionTypes.SET_PRODUCTS, available, offerLevelSweeteners };
};

export const setOfferType = (offerType: string): SetOfferTypeAction => {
  return { type: ActionTypes.SET_OFFER_TYPE, offerType };
};

export const setBroadbandType = (broadbandType: string): SetBroadbandTypeAction => {
  return { type: ActionTypes.SET_BROADBAND_TYPE, broadbandType };
};

export const setOfferObjects = (offerObjects: OfferObject[]): SetOfferObjectsAction => {
  return { type: ActionTypes.SET_OFFER_OBJECTS, offerObjects };
};

export const selectProduct = (
  selectedState: SelectedResumeProductsState | SelectedProductsState,
  isCopper: boolean
): SelectProductAction => {
  const { internet } = selectedState || {};
  const is200 = internet?.is200;
  const is500 = internet?.is500;
  const is1Gig = internet?.is1Gig;
  const is2Gig = internet?.is2Gig;
  const is5Gig = internet?.is5Gig;
  const is7Gig = internet?.is7Gig;

  // Data needed on the order details sidebar sweetener component
  const detailsBanner = [];
  // eero
  if (Boolean(internet?.sweeteners?.[EERO] || internet?.is2Gig || internet?.is5Gig || internet?.is7Gig)) {
    detailsBanner.push({
      name: 'eero',
      text: `${
        isCopper
          ? 'Amazon eero Wi-Fi router included'
          : is500 || is200
          ? 'Amazon eero 6+ router included'
          : is200
          ? 'Amazon eero 6+ router included'
          : is1Gig
          ? 'Amazon eero Pro 6 router included'
          : is2Gig
          ? 'Amazon eero Pro 6E router included'
          : is5Gig
          ? 'Amazon eero Max 7 router included'
          : is7Gig
          ? '2 Amazon eero Max 7 devices included'
          : ''
      }`,
    });
  }
  // vrc
  if (Boolean(internet?.sweeteners?.[VRC])) {
    detailsBanner.push({
      name: 'vrc',
      text: `+$${internet?.sweeteners?.[VRC]?.amount} Visa® Reward Card`,
    });
  }

  internet.detailsBanner = detailsBanner;

  return { type: ActionTypes.SELECT_PRODUCT, selectedState };
};

export const clearSelectProduct = (): ClearSelectProductAction => {
  return { type: ActionTypes.CLEAR_SELECT_PRODUCT };
};

export const setSelectedSweetener = (selectedSweetener: Option): SetSelectedSweetenerAction => {
  return { type: ActionTypes.SET_SELECTED_SWEETENER, selectedSweetener };
};

export const setSelectedNetflix = (selectedNetflix: boolean): SetSelectedNetflixAction => {
  return { type: ActionTypes.SET_NETFLIX_BUNDLE, selectedNetflix };
};

export const setPhoneSelection = (phoneSelection: string): SetPhoneSelection => {
  return { type: ActionTypes.SET_PHONE_SELECTION, phoneSelection };
};

export const setFirstAvailable = (firstAvailable: string): FirstAvailableAction => {
  return { type: ActionTypes.SET_FIRST_AVAILABLE, firstAvailable };
};

export const setNDIBanner = (ndiBanner: boolean): SetNDIBannerAction => {
  return { type: ActionTypes.SET_NDI_BANNER, ndiBanner };
};

export const setLandingPageProduct = (landingPageProduct: string): SetLandingPageProduct => {
  return { type: ActionTypes.SET_LANDING_PAGE_PRODUCT, landingPageProduct };
};

export const setLandingPageProductAvailable = (
  landingPageProductAvailable: boolean
): SetLandingPageProductAvailable => {
  return {
    type: ActionTypes.SET_LANDING_PAGE_PRODUCT_AVAILABLE,
    landingPageProductAvailable,
  };
};

export const setOfferModalSelected = (product: Product): SetOfferModalSelectedAction => ({
  type: ActionTypes.SET_OFFER_MODAL_SELECTED,
  product,
});

export const setIncludedWhiWholeHomeWifiPlus = (
  includedWithWholeHomeWifiPlus: boolean
): SetIncludedWhiWholeHomeWifiPlus => {
  return { type: ActionTypes.SET_INCLUDED_WITH_WHOLE_HOME_WIFI_PLUS, includedWithWholeHomeWifiPlus };
};

export const setCardConsolidation = (isEnabled: boolean): SetCardConsolidation => {
  return { type: ActionTypes.SET_CARD_CONSOLIDATION, isEnabled };
};

export const setCardConsolidationOption = (
  checked: boolean,
  option: string,
  selectedType: string
): SetCardConsolidationModal => {
  return { type: ActionTypes.SET_CARD_CONSOLIDATION_MODAL, checked, option, selectedType };
};
